import React from 'react';
import { FaSquareXTwitter } from 'react-icons/fa6';

const NavBar = () => {
  return (
    <nav className="bg-navbar p-4 shadow-lg">
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center space-x-2">
          <a href="/" className="flex items-center space-x-2 transform hover:scale-110 transition-transform duration-300">
            <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="WolfyFi Logo" className="h-12 w-12 sm:h-16 sm:w-16 rounded-full border-2 border-lightBlue shadow-lg" />
            <span className="text-lightBlue text-xl sm:text-2xl font-bold pl-0 sm:pl-3">WolfyFi</span>
          </a>
        </div>
        <div className="flex items-center space-x-6 sm:space-x-10 mt-4 sm:mt-0">
          <div className="flex items-center space-x-6">
            <a href="https://docs.wolfyfi.xyz" target="_blank" rel="noopener noreferrer" className="text-lightBlue hover:text-blue1 transition-colors duration-300 text-lg sm:text-xl">Docs</a>
            <a href="https://x.com/wolfyfixyz" target="_blank" rel="noopener noreferrer" className="text-lightBlue hover:text-blue1 transition-colors duration-300">
              <FaSquareXTwitter className="h-8 w-8 hover:scale-110 transition-transform duration-300" />
            </a>
          </div>
          <button
            className="bg-blue1 text-white py-3 px-6 rounded-lg hover:bg-blue2 transition-colors duration-300 transform hover:scale-105 shadow-lg text-lg sm:text-xl disabled:bg-blue1 disabled:cursor-not-allowed"
            disabled
          >
            App Launch
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
