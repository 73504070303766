import React from 'react';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import './App.css';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const App = () => {
  return (
    <div className="min-h-screen bg-darkBg text-lightBlue relative flex flex-col justify-between">
      <div>
        <NavBar />
        <MainContent />
      </div>
      <Footer />
    </div>
  );
}

export default App;
