import React from 'react';
import { FaSquareXTwitter, FaGithub, FaEnvelope } from 'react-icons/fa6';

const Footer = () => {
    return (
        <footer className="bg-footerBg p-4 text-gray-400 mt-8">
            <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
                <div className="flex items-center space-x-2">
                    <img src={`${process.env.PUBLIC_URL}/assets/starknet-logo.png`} alt="Starknet Logo" className="h-10 w-10 sm:h-12 sm:w-12" />
                    <span className="text-lg sm:text-xl font-bold">On Starknet</span>
                </div>
                <div className="flex items-center space-x-4 pl-32 ml-2  mt-4 sm:mt-0">
                    <a href="https://x.com/wolfyfixyz" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue1 transition-colors duration-300">
                        <FaSquareXTwitter className="h-6 w-6 hover:scale-110 transition-transform duration-300" />
                    </a>
                    <a href="https://github.com/wolfyfi" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue1 transition-colors duration-300">
                        <FaGithub className="h-6 w-6 hover:scale-110 transition-transform duration-300" />
                    </a>
                    <a href="mailto:wolfyfixyz@gmail.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue1 transition-colors duration-300">
                        <FaEnvelope className="h-6 w-6 hover:scale-110 transition-transform duration-300" />
                    </a>
                </div>
                <div className="mt-4 sm:mt-0 text-center sm:text-right">
                    <p className="text-xs sm:text-sm">This application is currently under development.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
