import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';
import nftData from '../data/nftData';

const MainContent = () => {
    return (
        <main className="container mx-auto p-4 sm:p-6 lg:p-8 flex-grow">
            <section className="text-center">
                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4">WolfyFi</h1>
                <p className="text-xl sm:text-2xl lg:text-3xl text-gray-400 mt-2">P2P NFT Lending dApp</p>
            </section>
            <section className="pt-4 sm:pt-6 lg:pt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
                <FeatureCard
                    image={`${process.env.PUBLIC_URL}/assets/wolfyfi_design_scheme.png`}
                    title="How Does WolfyFi Basically Work?"
                    description="The picture above basically explains how WolfyFi works with an example. For more, we recommend you to browse and review the docs section."
                />
                <NftSwiper />
            </section>
        </main>
    );
}

const FeatureCard = ({ image, title, description }) => (
    <div className="bg-greyBg p-6 rounded-lg shadow-lg">
        <img src={image} alt={title} className="rounded-lg mb-4" />
        <h2 className="text-2xl font-bold mb-2">{title}</h2>
        <p className="text-gray-500">{description}</p>
    </div>
);

const NftSwiper = () => (
    <div className="bg-greyBg p-6 rounded-lg shadow-lg flex justify-center">
        <Swiper
            effect="cube"
            grabCursor={true}
            cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
            }}
            pagination={{ clickable: true }}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[EffectCube, Pagination, Navigation, Autoplay]}
            className="mySwiper w-full max-w-md"
        >
            {nftData.map((nft, index) => (
                <SwiperSlide key={index} className="nft-slide relative flex items-center justify-center rounded-lg shadow-2xl">
                    <img
                        src={nft.image}
                        alt={nft.name}
                        className="rounded-lg w-full h-full object-cover z-10 shadow-lg"
                    />
                    <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white px-4 py-2 rounded-lg z-20">
                        <h2 className="text-2xl font-bold">{nft.name}</h2>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
);

export default MainContent;
