const nftData = [
    {
      name: 'STARKPUNKS',
      image: `${process.env.PUBLIC_URL}/assets/StarkPunks.png`
    },
    {
      name: 'STARKGUARDIANS',
      image: `${process.env.PUBLIC_URL}/assets/StarkGuardians.png`
    },
    {
      name: 'STARKROCKS',
      image: `${process.env.PUBLIC_URL}/assets/StarkRocks.png`
    }
  ];
  
  export default nftData;
  